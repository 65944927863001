<template>
  <div>
    <b-overlay :show="showTableOverlay" rounded="sm">
      <b-card style="max-width: 100%" class="mb-2">
        <div class="table-filters">
          <b-input-group class="mb-9">
            <vue-typeahead-bootstrap
              id="user"
              v-model="filterUser"
              :minMatchingChars="1"
              trim
              size="sm"
              @input="
                requestSuggestionVariants('userSuggestionProcessor', filterUser)
              "
              :data="suggestedVariants"
              :serializer="(s) => suggestFormater(s)"
              @hit="onUserSelected"
              :placeholder="$t('TRANSACTIONS.TYPE_USER_NAME')"
              ref="userTypeahead"
            >
            </vue-typeahead-bootstrap>

            <vue-typeahead-bootstrap
              id="identifier"
              v-model="filterIdentifier"
              :minMatchingChars="1"
              trim
              size="sm"
              @input="
                requestSuggestionVariants(
                  'identifierSuggestionProcessor',
                  filterIdentifier
                )
              "
              :data="suggestedVariants"
              :serializer="(s) => s.name"
              @hit="onIdentifierSelected"
              :placeholder="$t('TOKENS.TYPE_IDENTIFIER_VALUE')"
              ref="identifierTypeahead"
            >
            </vue-typeahead-bootstrap>

            <b-input-group-append>
              <b-button
                size="sm"
                text="Search"
                variant="success"
                @click="onFilterSearch"
                >{{ $t("COMMON.SEARCH") }}</b-button
              >
              <b-button
                size="sm"
                text="Clear"
                variant="secondary"
                @click="onFiltersClear"
                >{{ $t("COMMON.CLEAR") }}</b-button
              >
            </b-input-group-append>
          </b-input-group>
        </div>

        <b-table
          id="table"
          striped
          bordered
          :items="response.data"
          @sort-changed="sortingChanged"
          no-local-sorting
          responsive
          selectable
          select-mode="single"
          :fields="fields"
          @row-selected="onRowsSelected"
        ></b-table>

        <b-dropdown
          id="dropdown-show-by"
          :text="$t('COMMON.SHOW_BY') + ': ' + this.perPage"
          class="m-md-2"
          offset="-50"
          style="float: right"
        >
          <b-dropdown-item
            v-for="count in showPerPageArray"
            :key="count"
            @click="showPerPageChanged(count)"
            >{{ count }}</b-dropdown-item
          >
        </b-dropdown>

        <div
          v-bind:style="
            response.data.length === 0 || itemsTotal < perPage
              ? 'display:none;'
              : null
          "
        >
          <b-pagination
            v-model="currentPage"
            :page-class="!calculate ? 'hidden' : null"
            :last-class="!calculate ? 'hidden' : null"
            :first-class="!calculate ? 'hidden' : null"
            :hide-ellipsis="!calculate"
            :size="!calculate ? 'lg' : null"
            :per-page="perPage"
            @change="onPageSelected"
            :total-rows="itemsTotal"
            aria-controls="table"
          >
          </b-pagination>
        </div>
      </b-card>
    </b-overlay>

    <b-modal
      id="modal-delete-confirmation"
      :title="$t('COMMON.CONFIRMATION')"
      @ok="onDelete"
    >
      <p class="my-4" v-if="selectedItem">
        {{
          $t("COMMON.ARE_YOU_SURE_YOU_WANT_TO_DELETE", {
            name: selectedItem.name,
          })
        }}
      </p>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { API_REQUEST } from "@/core/services/store/api.module";
import { SET_ACTIONS } from "@/core/services/store/actions.module";
import Common from "../../common";
import SuggestionProcessorMixin from "../mixins/suggestionProcessorMixin";

export default {
  name: "tokens",
  mixins: [SuggestionProcessorMixin],
  components: {},
  data() {
    return {
      fields: [
        {
          key: "id",
          label: this.$t("TOKENS.VALUE"),
          sortingKey: "el.id",
          sortable: false,
        },
        {
          key: "userName",
          label: this.$t("COMMON.USER"),
          sortingKey: "el.user.id",
          sortable: true,
        },
        {
          key: "identifierValue",
          label: this.$t("TOKENS.IDENTIFIER"),
          sortingKey: "el.identifier.id",
          sortable: true,
        },
        {
          key: "active",
          label: this.$t("COMMON.ACTIVE"),
          formatter: Common.booleanFormatter,
          sortingKey: "el.active",
          sortable: true,
        },
        {
          key: "lastRequest",
          label: this.$t("TOKENS.LAST_REQUEST"),
          formatter: Common.idObjectFormatter,
          sortingKey: "el.lastRequest",
          sortable: true,
        },
      ],

      filterName: "",

      response: {
        data: [],
        totalCount: 1,
      },
      showPerPageArray: [10, 50, 100],

      getList: {
        resource: "/platform/api/token",
        requestType: "GET",
        requestParams: { enrich: true },
      },

      currentPage: 1, // page numbers indexed from 1
      perPage: 10,

      showTableOverlay: true,

      errors: [],
      calculate: false,
      selectedItem: null,

      selectedUserId: null,
      filterUser: null,

      selectedIdentifierId: null,
      filterIdentifier: null,
    };
  },

  computed: {
    itemsTotal() {
      return this.response.totalCount;
    },
  },

  mounted() {
    let that = this;
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("MENU.TOKENS") }]);
    this.$store.dispatch(SET_ACTIONS, [
      {
        label: this.$t("ACTIONS.CREATE_NEW"),
        action: this.onCreateNewAction,
        icon: "fas fa-plus",
      },
      {
        label: this.$t("ACTIONS.EDIT"),
        action: this.onEditAction,
        disabled: function () {
          return that.selectedItem === null || that.showTableOverlay;
        },
        icon: "fas fa-edit",
      },
      {
        label: this.$t("ACTIONS.DELETE"),
        action: this.onDeleteAction,
        disabled: function () {
          return that.selectedItem === null || that.showTableOverlay;
        },
        icon: "fas fa-trash-alt",
      },
      {
        label: this.$t("ACTIONS.RELOAD"),
        action: this.onReloadAction,
        icon: "fas fa-sync",
      },
    ]);
  },

  created() {
    this.getList.requestParams.count = this.perPage;
    this.getList.requestParams.calculate = this.calculate;
    this.loadList();
  },

  methods: {
    loadList: function () {
      this.showTableOverlay = true;
      this.$store
        .dispatch(API_REQUEST, this.getList)
        .then((response) => {
          this.response = Common.processEntityListResponse(
            this.response,
            response,
            this.currentPage,
            this.perPage,
            this.calculate
          );
          this.currentPage = this.response.noData
            ? this.currentPage - 1
            : this.currentPage;
          this.showTableOverlay = false;
        })
        .catch(this.onError);
    },

    onPageSelected: function (index) {
      this.getList.requestParams.start = (index - 1) * this.perPage;
      this.loadList();
    },

    onUserSelected: function (value) {
      this.selectedUserId = value.id;
    },

    onIdentifierSelected: function (value) {
      this.selectedIdentifierId = value.id;
    },

    onRowsSelected: function (items) {
      if (items !== undefined && items.length > 0) {
        this.selectedItem = items[0];
      } else {
        this.selectedItem = null;
      }
    },

    onCreateNewAction: function () {
      this.$router.push({ name: "token" });
    },

    onDeleteAction: function () {
      this.$bvModal.show("modal-delete-confirmation");
    },

    onEditAction: function () {
      this.$router.push({
        name: "token",
        params: { id: this.selectedItem.id },
      });
    },

    onReloadAction: function () {
      this.getList.requestParams.start = 0;
      this.currentPage = 1;
      this.loadList();
    },

    onDelete: function () {
      const del = {
        resource: "/platform/api/token/" + this.selectedItem.id + "/delete",
        requestType: "POST",
      };
      this.$store
        .dispatch(API_REQUEST, del)
        .then(() => {
          this.$bvToast.toast(this.$t("COMMON.DELETED_SUCCESSFULLY"), {
            title: this.$t("COMMON.SUCCESS"),
            variant: "success",
            autoHideDelay: 5000,
          });

          // update table
          this.currentPage = 1;
          this.getList.requestParams.start = 0;
          this.loadList();
        })
        .catch(this.onError);
    },

    showPerPageChanged: function (count) {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = count;
      this.perPage = count;

      this.loadList();
    },

    onError: function (response) {
      if (response && response.config) response = response.data;

      this.$bvToast.toast(
        response && response.message
          ? response.message
          : this.$t("COMMON.UNKNOWN_ERROR"),
        {
          title: this.$t("COMMON.ERROR"),
          variant: "danger",
          autoHideDelay: 5000,
        }
      );
    },

    sortingChanged: function (ctx) {
      this.currentPage = 1;
      this.getList.requestParams.sortDir = ctx.sortDesc ? "desc" : "asc";

      let field = this.fields.find((x) => x.key === ctx.sortBy);
      if (field != null && field.sortingKey != null) {
        this.getList.requestParams.sortField = field.sortingKey;
      } else {
        this.getList.requestParams.sortField = ctx.sortBy;
      }
      this.getList.requestParams.start = 0;
      this.loadList();
    },

    onFilterSearch: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;

      this.getList.requestParams["userId"] =
        this.selectedUserId != null ? this.selectedUserId : null;
      this.getList.requestParams["identifierId"] =
        this.selectedIdentifierId != null ? this.selectedIdentifierId : null;
      this.loadList();
    },

    onFiltersClear: function () {
      this.currentPage = 1;
      this.getList.requestParams.start = 0;
      this.getList.requestParams.count = this.perPage;

      delete this.getList.requestParams["userId"];
      delete this.getList.requestParams["identifierId"];

      this.selectedUserId = "";
      this.selectedIdentifierId = "";

      this.$refs.userTypeahead.$data.inputValue = "";
      this.$refs.identifierTypeahead.$data.inputValue = "";

      this.loadList();
    },

    suggestFormater: function (s) {
      let str = s.name;
      if (s.description) {
        str = str + "\n(" + s.description + ")";
      }
      return str;
    },
  },
};
</script>
